import React, { useEffect, useRef, useState } from "react";
// import { Icons, Images } from "../../constants";
// import CircleButton from "../ui/CircleButton";
// import uploadService from "../../services/uploadService";
import { useQuery } from "react-query";
import CustomButton from "../custom/CustomButton";
// import BoxUploadImage from "./BoxUploadImage";

const UploadImages = ({
  setValue,
  getValues,
  selectedImages,
  setSelectedImages,
  uploadProgress,
  setUploadProgress,
  oneFile,
}) => {
  const fileInputRef = useRef();
  const [imgsSelected, setImgsSelected] = useState([]);

  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files;
    const imagesArray = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );
    setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);

    // filter files not images
    const nonImageFiles = Array.from(files).filter(
      (file) => !file.type.startsWith("image/")
    );
    nonImageFiles.forEach((file) => {
      console.log("File is not an image:", file.name);
      // Perform any additional action for non-image files
    });

    imagesArray.forEach((file) => {
      uploadImage(file);
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("myFile", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${process.env.REACT_APP_API_URL}/upload`, true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress((prevProgress) => {
          const updatedProgress = new Map(prevProgress);
          updatedProgress.set(file.name, progress);
          return updatedProgress;
        });
        // setUploadProgress((prevProgress) => [
        //   ...prevProgress,
        //   { file, progress },
        // ]);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log("Image uploaded successfully");
        if (xhr.responseText) {
          const files = getValues().images;
          setValue("images", [...files, JSON.parse(xhr.responseText).fileName]);
        }
      } else {
        console.error("Image upload failed");
      }
    };

    xhr.send(formData);
  };

  const handleFileInputClick = (event) => {
    event.target.value = null;
  };

  const handleFileInputChange = (event) => {
    // let files;
    // if (oneFile) files = event.target.files[0];
    // else files = event.target.files;
    let files = event.target.files;

    const imagesArray = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );
    setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);

    // filter files not images
    const nonImageFiles = Array.from(files).filter(
      (file) => !file.type.startsWith("image/")
    );
    nonImageFiles.forEach((file) => {
      console.log("File is not an image:", file.name);
      // Perform any additional action for non-image files
    });

    imagesArray.forEach((file) => {
      uploadImage(file);
    });
  };

  return (
    <div>
      <div
        className={`drop-zone flex items-center justify-center flex-col border-2 border-dashed p-10 mb-4 bg-white rounded-lg overflow-hidden`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {/* <img src={Images.Upload} alt="upload image" className="w-36" /> */}
        <CustomButton
          name={"add file"}
          css={"!bg-white !w-fit !text-[#1E5EFF] !text-sm !py-2 !py-2 border"}
        />
        <p className="mt-3 text-sm text-gray-400">or drag and drop files</p>
      </div>
      <div className="space-y-2">
        {/* {selectedImages.map((image, idx) => (
          <div key={idx}> */}
        {/* <BoxUploadImage
              file={image}
              progress={
                uploadProgress.has(image.name)
                  ? uploadProgress.get(image.name)
                  : 0
              }
              handleDelete={() => handleDelete(idx)}
              imageNameInStorage={getValues().images[idx]}
            /> */}
        {/* </div>
        ))} */}
      </div>
    </div>
  );
};

export default UploadImages;
