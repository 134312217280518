import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useDisclosure,
  CircularProgress,
} from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import CardRdv from "./components/CardRdv";
import AddPatientForm from "../../../components/form/AddPatientForm";
import PatientData from "./components/PatientData";
import CardDocument from "./components/CardDocument";
import Pagination from "../../../layout/Pagination";
import { useGetAllWaitingList } from "../../../hooks/useWaitingListService";

const List = () => {
  const { t } = useTranslation("rdvs");

  const [selected, setSelected] = useState(0);

  const totalPages = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    console.log("Page changed to:", page);
    // Handle your data fetch or page change logic here
  };

  const { isLoading, data, isError, error, refetch } = useGetAllWaitingList();

  useEffect(() => {
    if (data && data.data && data.data.data && !selected)
      setSelected({ ...data.data.data[0], number: 0 });
  }, [data]);

  const navigate = useNavigate();

  return (
    <div className="">
      {isLoading ? (
        <div className="flex items-center justify-center min-h-[200px]">
          <CircularProgress isIndeterminate color="blue.400" />
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <p className="text-sm text-gray-400">
              {data?.data?.meta?.totalRecords} {t("general.results")}
            </p>
            <Pagination
              totalPages={data?.data?.meta?.totalPages}
              currentPage={data?.data?.meta?.currentPage}
              onPageChange={handlePageChange}
            />
          </div>
          <div className="grid  grid-cols-5 gap-4">
            {data &&
              data.data.data.map((item, idx) => (
                <CardRdv
                  key={idx}
                  item={{ ...item, number: idx }}
                  selected={selected.number === idx}
                  setSelected={setSelected}
                />
              ))}
          </div>

          <div className="border-b w-full mt-6 mb-4">
            <h3 className="border-b-2 text-primary-100 border-b-primary-100 w-fit py-2 first-letter:capitalize font-medium text-sm">
              {t("list.profile")}
            </h3>
          </div>
          <PatientData data={selected} />

          <div className="border-b w-full mt-6 mb-4">
            <h3 className="border-b-2 text-primary-100 border-b-primary-100 w-fit py-2 first-letter:capitalize font-medium text-sm">
              {t("list.documents")}
            </h3>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {selected &&
              selected?.documents.map((item, idx) => (
                <CardDocument item={item} />
              ))}
          </div>
        </div>
      )}
      <div className="flex items-center justify-end w-full">
        <button
          onClick={() => {
            navigate("service");
          }}
          className="bg-secondary-100 font-medium first-letter:capitalize px-4 py-2 rounded-full text-white"
        >
          {t("general.payment")}
        </button>
      </div>
    </div>
  );
};

export default List;
