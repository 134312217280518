import React from "react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { Icons, Images } from "../../constants";
import CustomButton from "../custom/CustomButton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircleButton from "../ui/CircleButton";
import CallendarRdv from "../ui/CallendarRdv";
import images from "../../constants/images";

const CardMedecin = ({ medecin, select = false }) => {
  const { id, fullName, specialtyName, address, phone, cabinName } = medecin;
  const navigate = useNavigate();
  const { t } = useTranslation("search");

  const handleRdv = () => {
    navigate("/doctors/" + id);
  };
  return (
    <div className="relative h-fit !w-fit group">
      {/* <div className={`mb-2 w-fit ${select ? "block" : "hidden"}`}>
        <Card borderRadius={"lg"}>
          <CardBody className="!px-0 !py-px flex items-center !w-fit">
            <CircleButton
              icon={Icons.EditRectangle}
              name={"icon edit"}
              cssIcon="!w-[20px] !md:w-4 !h-[20px] !md:h-4"
            />
            <CircleButton
              icon={Icons.Folder}
              name={"icon folder"}
              cssIcon="!w-[20px] md:w-4 !h-[20px] md:h-4"
            />
            <CircleButton
              icon={Icons.Notification}
              name={"icon notification"}
              cssIcon="!w-[20px] md:w-4 !h-[20px] md:h-4"
            />
          </CardBody>
        </Card>
      </div> */}
      <Card
        borderRadius={"xl"}
        className={`overflow-hidden ${select && "!border-blue-500 !border"}`}
      >
        <CardBody className="grid grid-cols-5 gap-4 !p-0 md:!p-4">
          <div className="flex items-start space-x-4 col-span-4 md:col-span-3 p-3 md:p-0 relative !z-30">
            <div className="bg-blue-500 bg-opacity-25 w-full rounded-lg overflow-hidden h-24">
              <img
                src={images.Indique}
                alt={`image ${fullName}`}
                className="w-full h-full rounded-xl object-cover"
              />
            </div>
            <div className="w-full">
              <h4
                className="text-green-700 text-sm md:text-base font-medium"
                onClick={handleRdv}
              >
                {fullName}
              </h4>
              <p className="text-zinc-600 text-xs md:text-sm font-normal capitalize">
                {specialtyName ?? "(vide)"}
              </p>
              <p className="text-slate-400 text-xs md:text-sm font-normal md:w-[350px] md:mt-2">
                {address}
              </p>
              <p className="text-zinc-600 text-xs md:text-sm font-normal md:mt-2 md:mb-2">
                {cabinName}
              </p>
              <div className="hidden md:block">
                <CustomButton
                  name={t("search:bookRDV")}
                  onClick={handleRdv}
                  css="w-full hidden md:block"
                />
              </div>
            </div>
          </div>
          <div className="w-full col-span-1 md:col-span-2">
            <div className="hidden md:block">
              <CallendarRdv />
            </div>
            <div className="bg-blue-600 text-white capitalize text-[8px] w-full h-full flex items-center justify-center md:hidden">
              <p>{t("doctor.available")}</p>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="absolute -left-12 top-0 hidden group-hover:block">
        <Card borderRadius={"lg"}>
          <CardBody className="!px-0 !py-2">
            <CircleButton
              icon={Icons.EditRectangle}
              name={"icon edit"}
              cssIcon="!w-[20px] !md:w-4 !h-[20px] !md:h-4"
            />
            <CircleButton
              icon={Icons.Folder}
              name={"icon folder"}
              cssIcon="!w-[20px] md:w-4 !h-[20px] md:h-4"
            />
            <CircleButton
              icon={Icons.Notification}
              name={"icon notification"}
              cssIcon="!w-[20px] md:w-4 !h-[20px] md:h-4"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default CardMedecin;
