import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useDisclosure,
} from "@chakra-ui/react";
import OptionCard from "./components/OptionCard";
import { useTranslation } from "react-i18next";
import icons from "../../../constants/icons";
import TitleCard from "./components/TitleCard";
import DataTableCard from "./components/DataTableCard";
import { useNavigate } from "react-router";
import { useGetAllNoteSend } from "../../../hooks/useNoteService";

const MyTeam = () => {
  const { t } = useTranslation("myTeam");
  const navigate = useNavigate();

  const { isLoading, data, isError, error, refetch } = useGetAllNoteSend();

  return (
    <div className="responsive mt-4">
      <div className="grid grid-cols-11 gap-4">
        <div className="col-span-11">{/* <TitleCard /> */}</div>
        <div className="col-span-2">
          <OptionCard
            icon={"pages"}
            title={t("general.notes")}
            description={t("general.notes-description")}
            onClick={() => navigate("./my-notes")}
          />
        </div>
        <div className="col-span-9 h-full row-span-2">
          <DataTableCard data={data?.data?.data ?? []} />
        </div>
        <div className="col-span-2">
          <OptionCard
            icon={"users"}
            title={t("general.users")}
            description={t("general.users-description")}
            onClick={() => navigate("./list")}
          />
        </div>
      </div>
    </div>
  );
};

export default MyTeam;
