import React, { Children } from "react";
import Navbar from "../layout/Navbar";
import { Icons, Images } from "../constants";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import CustomButton from "../components/custom/CustomButton";
import icons from "../constants/icons";
import Footer from "../layout/Footer";
import MakeRDVForm from "../components/form/MakeRDVForm";
import CircleButton from "../components/ui/CircleButton";
import NotificationBox from "../layout/NotificationBox";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import SearchForm from "../components/form/SearchForm";
import NavbarMobile from "../layout/NavbarMobile";

const DetailsMedecinPage = () => {
  const { t } = useTranslation("search");

  const navigate = useNavigate();

  return (
    <div>
      <Navbar>
        <SearchForm />
      </Navbar>
      <NavbarMobile />

      <div className="responsive">
        <div className="mt-4 mb-10 grid md:grid-cols-6 gap-x-8 2xl:gap-x-16">
          <div className="col-span-4 w-full">
            <div className="md:hidden">
              <Card
                borderRadius={"xl"}
                className="overflow-hidden !rounded-none md:!rounded-xl"
              >
                <CardBody className="bg-blue-500">
                  <div className="flex items-center space-x-4 ">
                    <img
                      src={Images.HeroSection}
                      alt={`icon`}
                      className="w-16 h-16 rounded-full object-cover"
                    />
                    <div className="text-white w-full">
                      <h3 className="text-sm font-bold capitalize">
                        {"dr. moasdfasdf"}
                      </h3>
                      <p className="text-xs capitalize">medecin general</p>
                      <div className="mt-4 flex items-center space-x-4 !text-xs">
                        <CustomButton
                          name={t("general_search.bookRDV")}
                          css="w-full !font-medium !bg-white !text-[#40B745]"
                          onClick={() => navigate("/make-rdv")}
                        />
                        <div className="">
                          <CustomButton
                            name={""}
                            icon={Icons.PhoneWhite}
                            cssImg="!w-8"
                            css="!bg-[#40B745] !h-10 !w-12 !p-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="space-y-4">
              <div className="hidden md:block">
                <InfoSection
                  icon={Icons.FileDuplicateBlue}
                  title={t("doctor.services")}
                >
                  <p className="text-zinc-600 text-sm ">
                    Transmettez dès maintenant l'ordonnance à votre pharmacie en
                    toute securité.
                  </p>
                  <div className="mt-4 flex items-center space-x-4">
                    <div className="w-[300px]">
                      <CustomButton name={t("general_search.bookRDV")} />
                    </div>
                    <div className="w-16">
                      <CustomButton
                        icon={Icons.PhoneWhite}
                        css="!bg-[#40B745] !h-full !px-4"
                      />
                    </div>
                  </div>
                </InfoSection>
              </div>
              <InfoSection
                icon={Icons.TelescopeBlue}
                title={t("doctor.expertise")}
              >
                <div className="">
                  <p className="mr-2 mb-2 inline-block text-zinc-600 text-sm font-normal leading-tight bg-gray-200 w-fit rounded px-2 py-1">
                    Vaccination
                  </p>
                  <p className="mr-2 mb-2 inline-block text-zinc-600 text-sm font-normal leading-tight bg-gray-200 w-fit rounded px-2 py-1">
                    Vaccination
                  </p>
                  <p className="mr-2 mb-2 inline-block text-zinc-600 text-sm font-normal leading-tight bg-gray-200 w-fit rounded px-2 py-1">
                    Vaccination
                  </p>
                  <p className="mr-2 mb-2 inline-block text-zinc-600 text-sm font-normal leading-tight bg-gray-200 w-fit rounded px-2 py-1">
                    Vaccination
                  </p>
                  <p className="mr-2 mb-2 inline-block text-zinc-600 text-sm font-normal leading-tight bg-gray-200 w-fit rounded px-2 py-1">
                    Vaccination anti Covid
                  </p>
                </div>
              </InfoSection>
              <InfoSection icon={Icons.locationBlue} title={t("doctor.access")}>
                <div className="text-zinc-600 text-sm order-1 md:order-2">
                  <p>Pharmacie des 2 Rues</p>
                  <p>100 Avenue Jean Jaurès 75019 Paris</p>
                  <h5 className="text-base font-semibold mt-2">
                    {t("doctor.usefulInfo")}
                  </h5>
                  <p>Accès pour personnes à mobilité réduite: oui</p>
                  <h5 className="text-base font-semibold mt-2">
                    {t("doctor.transport")}
                  </h5>
                  <p>Mértro Laumière : Ligne 5</p>
                  <p className="mt-4 text-teal-400 cursor-pointer underline">
                    {t("doctor.seeMap")}
                  </p>
                </div>
                <div className="md:absolute right-0 top-0 h-full md:w-1/2 mt-4 md:mt-0 -ml-4 -mr-4 md:ml-0 md:mr-0 md:rounded-xl overflow-hidden">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12786.789234004953!2d3.04760565!3d36.753836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fb260df364515%3A0xe3252415c67d7fa3!2sMustapha%20University%20Hospital%20Center!5e0!3m2!1sen!2sdz!4v1702486669956!5m2!1sen!2sdz"
                    width="100%"
                    height="100%"
                    // style={{ borderRadius: "12px" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </InfoSection>
              <InfoSection icon={Icons.InfoBlue} title={t("doctor.info")}>
                <div className="text-zinc-600 text-sm space-y-2">
                  <p>
                    La pharmacie des Deux Rues vous accueille au 100 Avenue Jean
                    Jaurès, à Paris 19.{" "}
                  </p>
                  <p>
                    L'équipe, se tient à votre disposition pour votre traitement
                    médical et vos conseils pharmaceutiques.
                  </p>
                  <p>
                    La pharmacie est disponible sur place, par mail, ou par
                    téléphone pour répondre à vos besoins.
                  </p>
                  <p>
                    La pharmacie propose également un service de
                    Téléconsultation Assistée vous permettant de consulter un
                    médecin ou un spécialiste, en limitant vos déplacements.
                    Elle dispose de matériel médical connecté pour répondre à
                    vos besoins de santé dans le cadre du parcours de soins.
                  </p>
                  <p>
                    Des pharmaciens vous reçoivent dans un lieu adapté pour vous
                    aider à choisir votre prothèse mammaire et capillaire et
                    vous conseiller au mieux tout au long de votre traitement en
                    oncologie.
                  </p>
                  <h5 className="text-base font-semibold mt-2">
                    {t("doctor.spokenLang")}
                  </h5>
                  <p>Arabe, Allemand, Hindi, Anglais</p>
                  <div className="flex items-center space-x-2 mt-2">
                    <h5 className="text-base font-semibold">
                      {t("doctor.refunds")}
                    </h5>
                    <img src={Icons.Info} alt="icon info" />
                  </div>
                  <Card className="overflow-hidden !rounded-none md:!rounded-xl !hidden md:!block">
                    <CardBody className="flex items-center bg-blue-700 text-white space-x-4">
                      <img src={Images.Indique} alt="image indique" />
                      <div className="w-full">
                        <h4 className="text-sm md:text-lg font-bold">
                          {t("note.titleIndicate")}
                        </h4>
                        <div className="flex items-center justify-between mt-2 text-xs md:text-base">
                          <p>{t("note.descriptionIndicate")}</p>
                          <img
                            src={Icons.RightArrow}
                            alt="icon right arrow"
                            className="hidden md:block"
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </InfoSection>
              <Card className="overflow-hidden md:!hidden !rounded-none">
                <CardBody className="flex items-center bg-blue-700 text-white space-x-4">
                  <img src={Images.Indique} alt="image indique" />
                  <div className="w-full">
                    <h4 className="text-sm md:text-lg font-bold">
                      {t("note.titleIndicate")}
                    </h4>
                    <div className="flex items-center justify-between mt-2 text-xs md:text-base">
                      <p>{t("note.descriptionIndicate")}</p>
                      <img
                        src={Icons.RightArrow}
                        alt="icon right arrow"
                        className="hidden md:block"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <InfoSection
                icon={Icons.TimeTableBlue}
                title={t("doctor.hoursContact")}
              >
                <ul className="w-2/3 md:w-1/3">
                  <li className="grid grid-cols-2 gap-x-6 text-sm">
                    <p className="capitalize">lundi</p>
                    <p>08:30 - 20:30</p>
                  </li>
                  <li className="grid grid-cols-2 gap-x-6 text-sm">
                    <p className="capitalize">lundi</p>
                    <p>08:30 - 20:30</p>
                  </li>
                  <li className="grid grid-cols-2 gap-x-6 text-sm">
                    <p className="capitalize">lundi</p>
                    <p>08:30 - 20:30</p>
                  </li>
                  <li className="grid grid-cols-2 gap-x-6 text-sm">
                    <p className="capitalize">lundi</p>
                    <p>08:30 - 20:30</p>
                  </li>
                </ul>
                <div className="flex items-center space-x-2 text-sm mt-4">
                  <img src={icons.Phone} alt="icon phone" />
                  <p>01 43 03 23 23</p>
                </div>
                <h5 className="text-base font-semibold mt-2">
                  {t("doctor.prices")}
                </h5>
                <p>Le centre n’a malheureusement pas renseigné ses tarifs.</p>
              </InfoSection>
            </div>
          </div>
          <div className="col-span-2 hidden md:block">
            <Outlet />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DetailsMedecinPage;

const InfoSection = ({ icon, title, children }) => {
  return (
    <Card borderRadius={"md"} className=" !rounded-none md:!rounded-md">
      <CardBody>
        <div className="flex items-center space-x-2 mb-2">
          <img src={icon} alt={`icon ${title}`} className="w-5 h-5" />
          <h3 className="text-blue-500 text-base font-bold capitalize">
            {title}
          </h3>
        </div>
        {children}
      </CardBody>
    </Card>
  );
};
