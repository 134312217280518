import React from "react";
import { Icons } from "../../constants";
import { useTranslation } from "react-i18next";
// import InputCustom from "../ui/InputCustom";

const SearchForm = () => {
  const { t } = useTranslation("global");
  return (
    <form className="flex space-x-2 bg-gray-100 rounded-lg px-4 py-2">
      <img src={Icons.Search} alt="icon search" className="w-4 md:w-fit" />
      <input
        type="text"
        placeholder={t("navbar.quick_search")}
        className="bg-transparent outline-none w-full text-sm"
      />
    </form>
  );
};

export default SearchForm;
