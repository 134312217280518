import React from "react";

const CardEdit = ({ children }) => {
  return (
    <div className="w-full h-full border rounded-xl border-gray-200 py-4 px-4">
      {children}
    </div>
  );
};

export default CardEdit;
