import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CardInput from "./components/CardInput";
import icons from "../../../constants/icons";
import { Alert, AlertIcon, CircularProgress, Select } from "@chakra-ui/react";
import {
  useCreateSubAdmin,
  useEditProvider,
  useGetAllSubAdmin,
  useSendNote,
} from "../../../hooks/useProviderService";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { Icons } from "../../../constants";

const AccountPage = () => {
  const { t } = useTranslation("settings");

  const [content, setContent] = useState("");
  const [receivers, setReceivers] = useState([]);

  const {
    register: registerSendNote,
    handleSubmit: handleSubmitSendNote,
    loading: loadingSendNote,
    onSubmit: onSubmitSendNote,
    error: errorSendNote,
    message: messageSendNote,
    setMessage: setMessageSendNote,
    setValue: setValueSendNote,
    watch: watchSendNote,
  } = useSendNote({
    title: "",
    content: "",
    receivers: [],
  });

  const {
    register,
    handleSubmit,
    loading,
    onSubmit,
    message,
    setMessage,
    setValue,
    watch,
    error,
  } = useCreateSubAdmin({
    username: "",
    password: "",
  });

  const {
    isLoading,
    data,
    isError,
    error: errorGetAllSubAdmin,
    refetch,
  } = useGetAllSubAdmin();

  const receiversData = watchSendNote("receivers");

  useEffect(() => {
    setValueSendNote("receivers", receivers);
  }, [receivers]);

  const handleChooseSubAdmin = async (id) => {
    if (id === "all") {
      const receivers = await data?.data?.data.map((item) => item.id);
      setReceivers(receivers);
    } else {
      setReceivers((r) => [...r, parseInt(id)]);
    }
  };

  useEffect(() => {
    setValueSendNote("content", content);
  }, [content]);

  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="space-y-3 border border-gray-300 rounded-xl p-4">
        <h3 className="first-letter:capitalize font-medium">
          {t("account.add-sub")}
        </h3>
        {loading ? (
          <div className="flex items-center justify-center min-h-[200px]">
            <CircularProgress isIndeterminate color="blue.400" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
            <div className="relative">
              <input
                type="text"
                className="border border-gray-300 rounded-xl pl-10 pr-4 py-2 outline-none w-full bg-gray-50"
                placeholder={t("account.username")}
                required
                {...register("username")}
              />
              <img
                src={icons.boxChevronDown}
                alt=""
                className="w-4 h-4 absolute left-4 top-1/2 -translate-y-1/2"
              />
            </div>
            <div className="relative">
              <input
                type="password"
                className="border border-gray-300 rounded-xl pl-10 pr-4 py-2 outline-none w-full bg-gray-50"
                placeholder={t("account.password")}
                required
                {...register("password")}
              />
              <img
                src={icons.boxChevronDown}
                alt=""
                className="w-4 h-4 absolute left-4 top-1/2 -translate-y-1/2"
              />
            </div>
            <div className="flex items-center justify-end w-full">
              <button
                type="submit"
                className="bg-primary-100 font-medium first-letter:capitalize px-4 py-2 rounded-full text-white"
              >
                {t("account.validate")}
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="space-y-3 border border-gray-300 rounded-xl p-4">
        <h3 className="first-letter:capitalize font-medium">
          {t("account.existing")}
        </h3>

        <div className="h-[140px] overflow-y-auto space-y-2">
          {data &&
            data?.data?.data.map((item) => (
              <div className="relative" key={item.id}>
                <p
                  type="text"
                  className="border border-gray-300 rounded-xl pl-4 pr-4 py-2 outline-none w-full bg-gray-50"
                  placeholder={t("account.username")}
                >
                  {item.username}
                </p>
                <div className="absolute right-4 top-1/2 -translate-y-1/2">
                  <div className="flex items-center space-x-2">
                    <div
                      className={`w-4 h-4 rounded-full bg-opacity-20 flex items-center justify-center ${
                        true ? "bg-green-400" : "bg-red-400"
                      }`}
                    >
                      <div
                        className={`w-2 h-2 rounded-full ${
                          true ? "bg-green-400" : "bg-red-400"
                        }`}
                      ></div>
                    </div>
                    <p
                      className={`${
                        true ? "text-green-400" : "text-red-400"
                      } font-medium first-letter:capitalize text-sm`}
                    >
                      {true ? t("account.active") : t("account.offline")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="relative py-2 w-full bg-primary-100 bg-opacity-10 rounded-xl flex items-center justify-center cursor-pointer">
          <div className="w-6 h-6 rounded-full bg-primary-100 bg-opacity-40 flex items-center justify-center">
            <img src={icons.PlusWhite} alt="" />
          </div>
        </div>
      </div>
      <div className="space-y-3 border border-gray-300 rounded-xl p-4">
        <h3 className="first-letter:capitalize font-medium">
          {t("account.send-notes")}
        </h3>

        <form onSubmit={handleSubmitSendNote(onSubmitSendNote)}>
          <div className="flex items-center space-x-2 mb-4">
            <p className="font-light text-sm">{t("edit-modal.sub-admin")} /</p>
            <div className="w-[150px]">
              <Menu closeOnSelect={false}>
                <MenuButton
                  type="button"
                  sx={{
                    border: "1px solid #2760F3",
                    backgroundColor: "#F5F9FF",
                    fontSize: 14,
                    padding: "2px 12px",
                    borderRadius: 5,
                  }}
                >
                  {t("edit-modal.choose")}
                </MenuButton>
                <MenuList>
                  {data &&
                    data?.data?.data.map((item) => (
                      <MenuItem
                        className="space-x-3 cursor-pointer"
                        key={item.id}
                        onClick={() => handleChooseSubAdmin(item.id)}
                      >
                        <input
                          type="checkbox"
                          name=""
                          id={"sub-admin-" + item.id}
                          className="cursor-pointer"
                        />
                        <label
                          htmlFor={`sub-admin-${item.id}`}
                          className="cursor-pointer"
                        >
                          {item.username}
                        </label>
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </div>
          </div>
          <div>
            <div className="rounded-xl p-4 text-sm bg-[#F5F9FF] h-fit">
              <textarea
                type="text"
                placeholder={t("account.placeholder-note")}
                className="text-[#627188] w-full h-[60px] bg-transparent outline-none"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="flex items-center justify-end w-full">
              {loadingSendNote ? (
                <div className="flex items-center justify-center min-h-[200px]">
                  <CircularProgress isIndeterminate color="blue.400" />
                </div>
              ) : (
                <button
                  type="submit"
                  className="mt-4 bg-secondary-100 font-medium first-letter:capitalize px-4 py-2 rounded-full text-white"
                >
                  {t("account.validate")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      {message && (
        <Alert status="success" className="col-span-3 rounded-md">
          <AlertIcon />
          {message}
        </Alert>
      )}
      {messageSendNote && (
        <Alert status="success" className="col-span-3 rounded-md">
          <AlertIcon />
          {messageSendNote}
        </Alert>
      )}
      {error && (
        <Alert status="error" className="col-span-3 rounded-md">
          <AlertIcon />
          {error}
        </Alert>
      )}
      {errorSendNote && (
        <Alert status="error" className="col-span-3 rounded-md">
          <AlertIcon />
          {errorSendNote}
        </Alert>
      )}
    </div>
  );
};

export default AccountPage;
