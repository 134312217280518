import React from "react";
import { useTranslation } from "react-i18next";

const CardBoxStat = () => {
  const { t } = useTranslation("stat");

  return (
    <div className="grid grid-cols-6 gap-4">
      <div className="bg-[#e3e5ff] rounded-xl p-3 space-y-3">
        <h3 className="text-sm font-semibold">{t("box.visits")}</h3>
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold">721k</p>
          <p className="text-xs text-gray-600">+11.01%</p>
        </div>
      </div>
      <div className="bg-[#e5ecf6] rounded-xl p-3 space-y-3">
        <h3 className="text-sm font-semibold">{t("box.patients")}</h3>
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold">721k</p>
          <p className="text-xs text-gray-600">+11.01%</p>
        </div>
      </div>
      <div className="bg-[#e3e5ff] rounded-xl p-3 space-y-3">
        <h3 className="text-sm font-semibold">{t("box.treated")}</h3>
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold">721k</p>
          <p className="text-xs text-gray-600">+11.01%</p>
        </div>
      </div>
      <div className="bg-[#e5ecf6] rounded-xl p-3 space-y-3">
        <h3 className="text-sm font-semibold">{t("box.views")}</h3>
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold">721k</p>
          <p className="text-xs text-gray-600">+11.01%</p>
        </div>
      </div>
      <div className="bg-[#e3e5ff] rounded-xl p-3 space-y-3">
        <h3 className="text-sm font-semibold">{t("box.views")}</h3>
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold">721k</p>
          <p className="text-xs text-gray-600">+11.01%</p>
        </div>
      </div>
      <div className="bg-[#e5ecf6] rounded-xl p-3 space-y-3">
        <h3 className="text-sm font-semibold">{t("box.views")}</h3>
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold">721k</p>
          <p className="text-xs text-gray-600">+11.01%</p>
        </div>
      </div>
    </div>
  );
};

export default CardBoxStat;
