import { changeLanguage } from "i18next";
import authService from "../services/authService";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { loginSlice, logoutSlice } from "../store/authSlice";
import authProviderService from "../services/authProviderService";

export const useMe = () => {
  const dispatch = useDispatch();

  const mePrestateur = async () => {
    try {
      const data = await authProviderService.me();

      const { role = "prestateur", ...user } = data;
      dispatch(
        loginSlice({
          isAuthenticated: true,
          userRole: role, // e.g., 'admin', 'user', 'guest'
          userData: user,
        })
      );

      // setTokens(access_token, refresh_token); // Store tokens in local storage or cookies
      // setIsAuthenticated(true);
    } catch (error) {
      console.log("@@@@--------");
      dispatch(logoutSlice({}));
      // localStorage.clear("userData");
      // localStorage.clear("accessToken");
      // navigate("/login");
      // window.location.pathname = "/";
      // throw new Error(error.response?.data?.message || "Fetch data failed");
    }
  };
  const mePatient = async () => {
    try {
      const data = await authService.me();

      const { role = "patient", ...user } = data;
      dispatch(
        loginSlice({
          isAuthenticated: true,
          userRole: role, // e.g., 'admin', 'user', 'guest'
          userData: user,
        })
      );

      // setTokens(access_token, refresh_token); // Store tokens in local storage or cookies
      // setIsAuthenticated(true);
    } catch (error) {
      console.log("@@@@--------");
      dispatch(logoutSlice({}));
      // localStorage.clear("userData");
      // localStorage.clear("accessToken");
      // navigate("/login");
      // window.location.pathname = "/";
      // throw new Error(error.response?.data?.message || "Fetch data failed");
    }
  };

  return { mePrestateur, mePatient };
};

export const useChangeInfo = (defaultValues) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: defaultValues,
  });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    setError("");
    setLoading(true);
    authService
      .login(data)
      .then((res) => {
        setMessage(res.data.message);
        navigate("/home");
        setLoading(false);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    message,
    setMessage,
    setValue,
    watch,
    error,
  };
};

export const useConfrimEmail = (email) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm(
    {
      defaultValues: {
        pin_1: "",
        pin_2: "",
        pin_3: "",
        pin_4: "",
      },
    }
  );

  const onSubmit = (data) => {
    setError("");
    setMessage("");
    setLoading(true);
    authService
      .confirmEmail({ ...data, email })
      .then((res) => {
        console.log(res.data.message);
        setMessage(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    loading,
    message,
    error,
  };
};

export const useAuth = (callback, isPatient = false) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      email: "derndjilali38@gmail.com",
      password: "dern3822",
    },
  });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    setError("");
    setLoading(true);
    // callback(data)
    callback(
      isPatient
        ? {
            email: "djder19@gmail.com",
            password: "dern3822",
          }
        : {
            email: "derndjilali38@gmail.com",
            password: "dern3822",
          }
    )
      .then((res) => {
        localStorage.setItem("accessToken", res.data.token);
        localStorage.setItem("userData", JSON.stringify(res.data.info));
        setMessage(res.data.message);
        if (isPatient) {
          navigate("/home");
        } else navigate("/prestateur/rdvs");
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    message,
    setMessage,
    error,
  };
};

export const useRegister = (callback = () => {}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm(
    {
      defaultValues: {
        fullName: "mohamed ben",
        birthday: "11-11-2023",
        sexe: "male",
        phone: "123456789",
        password: "123456",
        birthday: "11/11/1999",
        codePostal: 16012,
        SSNum: "HDH$&JVRW9",
        email: "ben@mail.com",
      },
    }
  );

  const onSubmit = (data) => {
    setError("");
    setLoading(true);
    authService
      .register(data)
      .then((res) => {
        if (res.status === 400) {
          setError(res.message);
        } else {
          // sessionStorage.setItem("localStorage.getItem("accessToken")", res.access_token);
          // sessionStorage.setItem("userData", JSON.stringify(res.data));
          // setMessage(res.message);
          // navigate("/home");
          // reset();
          callback();
        }
        setLoading(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    message,
    setMessage,
    error,
    watch,
  };
};
