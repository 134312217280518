import providerService from "../services/providerService";

import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { providerActions } from "../store/provider/provider-slice";
import { useDispatch, useSelector } from "react-redux";
import waitingListService from "../services/waitingListService";
import noteService from "../services/noteService";
import invoiceService from "../services/invoiceService";

export const useGetAllInvoice = () => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    "invoice-list",
    () => invoiceService.getAllInvoice()
  );

  return { isLoading, data, isError, error, refetch };
};

export const useGetDetailInvoice = (id) => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    `invoice-detail-${id}`,
    () => invoiceService.getInvoice(id)
  );

  return { isLoading, data, isError, error, refetch };
};
