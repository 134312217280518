import Indique from "../assets/img/indique image.svg";
import BannerWelcome from "../assets/img/banner_welcome_section.png";
import HeroSection from "../assets/img/hero_section.png";
import WhyImg from "../assets/img/why_img.png";
import WhyImg2 from "../assets/img/why_img2.png";
import RDV1 from "../assets/img/Rectangle 24.png";
import RDV2 from "../assets/img/Rectangle 25.png";
import RDV3 from "../assets/img/RDV3.jpeg";
import RDV5 from "../assets/img/RDV5.jpg";
import RDV6 from "../assets/img/RDV6.jpg";
import Test from "../assets/img/test.png";
import Hospital from "../assets/img/hospital.svg";
import NotFound from "../assets/img/notfound.svg";
import AvatarMan from "../assets/img/avatar-man.png";
import AvatarWoman from "../assets/img/avatar-woman.png";

export default {
  AvatarMan,
  AvatarWoman,
  NotFound,
  Hospital,
  Indique,
  BannerWelcome,
  HeroSection,
  WhyImg,
  WhyImg2,
  RDV1,
  RDV2,
  RDV3,
  RDV5,
  RDV6,
  Test,
};
