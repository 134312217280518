import React, { useRef, useState } from "react";
import Footer from "../layout/Footer";

import NavbarWelcome from "../layout/NavbarWelcome";
import AboutUsSection from "../components/sections/AboutUsSection";
import BannerSection from "../components/sections/BannerSection";
import RDVSection from "../components/sections/RDVSection";
import PartnerSection from "../components/sections/PartnerSection";
import HeroSection from "../components/sections/HeroSection";

const WelcomePage = () => {
  return (
    <div className="bg-[#F7F7F7]">
      <NavbarWelcome />
      {/* <NavbarMobile /> */}

      {/* hero section  */}
      <HeroSection />
      {/* why section  */}
      <AboutUsSection />
      {/* banner section */}
      <BannerSection />
      {/* rdv section  */}
      <RDVSection />
      {/* partners section  */}
      <PartnerSection />
      {/* footer  */}
      <Footer />
    </div>
  );
};

export default WelcomePage;
