import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WelcomeCard from "../cards/WelcomeCard";
import AuthCard from "../cards/AuthCard";
import SignInForm from "../form/SignInForm";
import SignUpForm from "../form/SignUpForm";
import { Icons, Images } from "../../constants";

const HeroSection = () => {
  const { t } = useTranslation("welcome");

  const type = useSelector((state) => state.auth.type);
  const step = useSelector((state) => state.auth.step);

  return (
    <div
      className={`bg-[#FCF8F3] relative h-screen flex items-center justify-end`}
      // style={imgRef.current && styleHeight}
    >
      <img
        src={Images.HeroSection}
        alt="image hero section"
        className="h-screen object-cover"
      />
      <div className="absolute left-0 top-0 bottom-0 right-0 z-20">
        <div className="responsive w-full h-full z-30 grid grid-cols-12 gap-6 2xl:gap-0">
          <div className="col-span-12 md:col-span-5 2xl:col-span-4 flex items-center ">
            <div className="bg-white bg-opacity-60 bg-blur backdrop-filter backdrop-blur-lg p-10 md:p-14 rounded h-fit w-full">
              {type === "" && (
                <>
                  {step === 1 && <WelcomeCard />}
                  {step === 2 && <AuthCard />}
                </>
              )}
              {type === "sign-in" && <SignInForm />}
              {type === "sign-up" && <SignUpForm />}
            </div>
          </div>
          <div className="col-start-10 col-span-3 items-end w-full pb-[90px] 2xl:pb-[170px] hidden md:flex">
            <div className="w-full bg-white h-fit bg-opacity-70 backdrop-blur-[15px] pb-6 px-6 pt-20">
              <div className="flex items-center justify-between">
                <p>
                  +{34535} {t("hero_section.clinic")}
                </p>
                <img src={Icons.RightArrowLine2} alt="icon right arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
