import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchForm from "../components/form/SearchForm";
import NotificationBox from "../layout/NotificationBox";
import CircleButton from "../components/ui/CircleButton";
import NavbarMobile from "../layout/NavbarMobile";
import { Icons, Images } from "../constants";
import { NavLink } from "react-router-dom";
import Navbar from "../layout/Navbar";
import { useGetAllMsg } from "../hooks/useMsgService";
import { Spinner } from "@chakra-ui/react";
const MessagePage = () => {
  const { t } = useTranslation("global");
  const { isLoading, data, fetchData } = useGetAllMsg();

  useEffect(() => {
    fetchData();
  }, []);

  console.log("@@@@", data);

  return (
    <div className="mb-20">
      <div className="bg-white rounded p-4 border-b">
        <h1 className="mb-2 font-semibold text-xl capitalize">
          {t("message.title")}
        </h1>
        <SearchForm />
      </div>
      <div className="bg-white rounded px-4 border-b">
        {isLoading ? (
          <div className="w-full flex items-center justify-center h-[300px]">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        ) : (
          data.map((item) => (
            <div
              key={item}
              className={`${item < 10 && "border-b"} pb-3 pt-3 px-4`}
            >
              <ItemMsg key={item} item={null} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MessagePage;

const ItemMsg = ({ item }) => {
  return (
    <div className="relative w-full">
      <div className="absolute -left-3 top-1/2 -translate-y-1/2 w-2 h-2 rounded-full bg-blue-600"></div>
      <div className="flex items-center space-x-4">
        <img
          src={Images.RDV2}
          alt=""
          className="w-11 h-11 rounded-full object-cover"
        />
        <div className="w-full">
          <div className="flex items-center justify-between">
            <h5 className="font-medium">Lorem, ipsum.</h5>
            <p className="text-[#3c3c4399] text-sm">9:40 PM</p>
          </div>
          <p className="text-[#3c3c4399] text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione,
            nihil.
          </p>
        </div>
      </div>
    </div>
  );
};
