import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Select,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import SelectPatientBox from "../ui/SelectPatientBox";
import UploadImages from "../ui/UploadImages";
import CallendarRdv from "../ui/CallendarRdv";
import CustomButton from "../custom/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "./../../store/popup/alert-slice";

const MakeRDVForm = () => {
  const dispatch = useDispatch();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 5,
  });

  const nextStep = () => {
    if (activeStep < 5) setActiveStep(() => activeStep + 1);
    else {
      dispatch(alertActions.replaceData("test"));
    }
  };

  return (
    <div>
      <Stepper
        size="sm"
        colorScheme="green"
        index={activeStep}
        orientation="vertical"
        gap="0px"
      >
        <Step1 />
        {activeStep > 0 && <Step2 />}
        {activeStep > 1 && <Step3 />}
        {activeStep > 2 && <Step4 />}
        {activeStep > 3 && <Step5 />}
        {activeStep > 4 && <Step6 />}
        <div className="w-40 mx-auto">
          <CustomButton name={"valide"} onClick={nextStep} />
        </div>
      </Stepper>
    </div>
  );
};

export default MakeRDVForm;

const Step1 = () => {
  const { t } = useTranslation("search");

  return (
    <Step className="w-full pb-8">
      {/* <StepIndicator active={true}> */}
      <StepIndicator>
        <StepStatus
          complete={<StepIcon />}
          incomplete={<StepNumber />}
          active={<StepNumber />}
        />
      </StepIndicator>

      <div className="w-full">
        <StepTitle>{t("doctor.speciality")}</StepTitle>
        <StepDescription>
          <input
            type="text"
            name=""
            id=""
            className="bg-gray-100 px-3 py-3 placeholder:capitalize rounded mt-2 w-full"
            placeholder={t("doctor.speciality_1")}
          />
        </StepDescription>
      </div>

      <StepSeparator />
    </Step>
  );
};

const Step2 = () => {
  const { t } = useTranslation("search");

  return (
    <Step className="w-full pb-8">
      <StepIndicator>
        <StepStatus
          complete={<StepIcon />}
          incomplete={<StepNumber />}
          active={<StepNumber />}
        />
      </StepIndicator>

      <div className="w-full">
        <StepTitle>{t("doctor.reasonConsultation")}</StepTitle>
        <div className="mt-2">
          <Select
            placeholder="Select option"
            className="mt-2 text-sm placeholder:text-sm"
          >
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <p className="text-end mt-2 text-sm">* {t("doctor.fillInfo")}</p>

          <Card className="overflow-hidden mt-4">
            <CardBody className="bg-orange-100 flex space-x-2">
              <div className="w-5 h-5 bg-orange-500 rounded-full flex items-center justify-center">
                <p className="text-white text-sm font-bold font-['Poppins']">
                  i
                </p>
              </div>
              <p className="text-neutral-800 text-sm font-norma w-[90%]">
                {t("doctor.alert_1")}
              </p>
            </CardBody>
          </Card>
        </div>
      </div>

      <StepSeparator />
    </Step>
  );
};

const Step3 = () => {
  const { t } = useTranslation(["search", "global"]);

  return (
    <Step className="w-full pb-8">
      <StepIndicator>
        <StepStatus
          complete={<StepIcon />}
          incomplete={<StepNumber />}
          active={<StepNumber />}
        />
      </StepIndicator>

      <div className="w-full">
        <StepTitle>{t("doctor.choosePattern")}</StepTitle>
        <div className="mt-2">
          <ul className="bg-gray-100 rounded-md space-y-2 grid grid-cols-1 p-4 mt-3 font-medium">
            <li
              value="1"
              className="bg-green-500 text-white flex items-center justify-center capitalize rounded py-2"
            >
              {t("filter:video")}
            </li>
            <li
              value="2"
              className="bg-white flex items-center justify-center capitalize rounded py-2"
            >
              {t("filter.home")}
            </li>
            <li
              value="3"
              className="bg-white flex items-center justify-center capitalize rounded py-2"
            >
              {t("filter.office")}
            </li>
          </ul>
        </div>
      </div>

      <StepSeparator />
    </Step>
  );
};

const Step4 = () => {
  const { t } = useTranslation(["search", "global"]);

  return (
    <Step className="w-full pb-8">
      <StepIndicator>
        <StepStatus
          complete={<StepIcon />}
          incomplete={<StepNumber />}
          active={<StepNumber />}
        />
      </StepIndicator>

      <div className="w-full">
        <StepTitle>{t("doctor.patient")}</StepTitle>
        <div className="mt-2"></div>
        <SelectPatientBox />
      </div>

      <StepSeparator />
    </Step>
  );
};

const Step5 = () => {
  const { t } = useTranslation(["search", "global"]);

  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);

  return (
    <Step className="w-full pb-8">
      <StepIndicator>
        <StepStatus
          complete={<StepIcon />}
          incomplete={<StepNumber />}
          active={<StepNumber />}
        />
      </StepIndicator>

      <div className="w-full">
        <StepTitle>{t("doctor.patient")}</StepTitle>
        <div className="mt-2"></div>
        <UploadImages
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          uploadProgress={uploadProgress}
          setUploadProgress={setUploadProgress}
        />
      </div>

      <StepSeparator />
    </Step>
  );
};

const Step6 = () => {
  const { t } = useTranslation(["search", "global"]);

  return (
    <Step className="w-full pb-8">
      <StepIndicator>
        <StepStatus
          complete={<StepIcon />}
          incomplete={<StepNumber />}
          active={<StepNumber />}
        />
      </StepIndicator>

      <div className="w-full">
        <StepTitle>{t("doctor.patient")}</StepTitle>
        <div className="mt-2">
          <CallendarRdv cssTimeBox={"py-8"} res={"asdf"} />
        </div>
      </div>

      {/* <StepSeparator /> */}
    </Step>
  );
};
