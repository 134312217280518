import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SearchMedecinPage from "./pages/SearchMedecinPage";
import DetailsMedecinPage from "./pages/DetailsMedecinPage";
import ScrollToTop from "./layout/ScrollToTop";
import WelcomePage from "./pages/WelcomePage";
import PopUpAlert from "./components/ui/PopUpAlert";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "@chakra-ui/react";
import { CircularProgress } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import MakeRdvPage from "./pages/MakeRdvPage";
import RDVPage from "./pages/RDVPage";
import NotificationPage from "./pages/NotificationPage";
import MessagePage from "./pages/MessagePage";
import DashboardPage from "./pages/DashboardPage";
import { useGetAll } from "./hooks/useSamples";
import categoryService from "./services/categoryService";
import WaitingListPage from "./pages/provider/WaitingListPage";
import DashboardProviderPage from "./pages/provider/DashboardProviderPage";
import PatientProfile from "./pages/provider/PatientProfile";
import WaitingListDetailPage from "./pages/provider/WaitingListDetailPage";
import NewPatient from "./pages/provider/NewPatient";
import ExistPatient from "./pages/provider/ExistPatient";
import SettingsPage from "./pages/provider/Settings/SettingsPage";
import FacturePage from "./pages/provider/Facture/Facture";
import FactureListPage from "./pages/provider/Facture/List";
import FactureDetailPage from "./pages/provider/Facture/Detail";
import AdminPage from "./pages/provider/Settings/AdminPage";
import AccountPage from "./pages/provider/Settings/AccountPage";
import ServicePage from "./pages/provider/Settings/ServicePage";
import MyTeam from "./pages/provider/MyTeam/MyTeam";
import MyNotes from "./pages/provider/MyNotes/MyNotes";
import MyTeamList from "./pages/provider/MyTeam/MyTeamList";
import ListRdvs from "./pages/provider/RDV/Rdvs";
import List from "./pages/provider/RDV/List";
import Rdvs from "./pages/provider/RDV/Rdvs";
import Service from "./pages/provider/RDV/Service";
import Stats from "./pages/provider/Stat/Stats";
import NotFoundPage from "./pages/NotFoundPage";
import { useMe } from "./hooks/useAuthService";
import MyRDVPage from "./pages/MyRDVPage";
import FavoritePage from "./pages/FavoritePage";
import DocumentPage from "./pages/DocumentPage";
import MyNotePage from "./pages/MyNotePage";
import MyPatientPage from "./pages/MyPatientPage";
import PatientDetailPage from "./pages/PatientDetailPage";
import ProfilePage from "./pages/ProfilePage";

function App() {
  const { t } = useTranslation("popup");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const { mePrestateur } = useMe();

  const alert = useSelector((state) => state.alert.item);
  useEffect(() => {
    mePrestateur();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (alert) onOpen();
  }, [alert]);

  return (
    <div className="mb-[60px] md:mb-0">
      {loading ? (
        <div className="w-full flex items-center justify-center h-screen">
          <CircularProgress isIndeterminate color="blue.400" />
        </div>
      ) : (
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<WelcomePage />} />
            {/* dashboard for providers  */}
            <Route exact path="prestateur" element={<DashboardProviderPage />}>
              <Route exact path="waiting-list" element={<WaitingListPage />}>
                <Route
                  exact
                  path="requests"
                  element={<WaitingListDetailPage />}
                />
              </Route>
              <Route exact path="patient-profile" element={<PatientProfile />}>
                <Route exact path="add" element={<NewPatient />} />
                <Route exact path="exist" element={<ExistPatient />} />
              </Route>
              <Route exact path="settings" element={<SettingsPage />}>
                <Route exact path="admin" element={<AdminPage />} />
                <Route exact path="accounts" element={<AccountPage />} />
                <Route exact path="my-clinic" element={<ServicePage />} />
              </Route>
              <Route exact path="invoices" element={<FacturePage />}>
                <Route exact index element={<FactureListPage />} />
                <Route exact path=":id" element={<FactureDetailPage />} />
              </Route>
              <Route exact path="my-team">
                <Route exact index element={<MyTeam />} />
                <Route exact path="list" element={<MyTeamList />} />
                <Route exact path="my-notes" element={<MyNotes />} />
              </Route>
              <Route exact path="rdvs" element={<Rdvs />}>
                <Route exact index element={<List />} />
                <Route exact path="service" element={<Service />} />
                {/* <Route exact path="my-notes" element={<MyNotes />} /> */}
              </Route>
              <Route exact path="stats" element={<Stats />} />
            </Route>
            {/* dashboard for patients  */}
            <Route exact path="home" element={<DashboardPage />}>
              <Route exact index element={<HomePage />} />
              <Route exact path="messages" element={<MessagePage />} />
              <Route exact path="favorites" element={<FavoritePage />} />
              <Route exact path="my-rdvs" element={<MyRDVPage />} />
              <Route exact path="documents" element={<DocumentPage />} />
              {/* <Route exact path="my-notes" element={<MyNotePage />} /> */}
              <Route exact path="add-patient" element={<PatientDetailPage />} />
              <Route
                exact
                path="my-patient/:id"
                element={<PatientDetailPage show />}
              />
              <Route exact path="my-patient" element={<MyPatientPage />} />
              <Route exact path="profile" element={<ProfilePage />} />
              <Route
                exact
                path="notifications"
                element={<NotificationPage />}
              />
            </Route>
            <Route exact path="make-rdv" element={<RDVPage />}>
              <Route index element={<MakeRdvPage />} />
            </Route>
            <Route exact path="doctors">
              <Route index element={<SearchMedecinPage />} />
              <Route exact path=":id" element={<DetailsMedecinPage />}>
                <Route index element={<MakeRdvPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      )}
      <PopUpAlert isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <p className="text-center">
          {t("detail-doctor.validation-description")}
        </p>
        <p className="text-center p-5 mt-4 bg-[#DBE6FF]">
          {t("detail-doctor.validation-indice")}
        </p>
      </PopUpAlert>
    </div>
  );
}

export default App;
