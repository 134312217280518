import React from "react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { Images } from "../../constants";
import CustomButton from "../custom/CustomButton";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const CardMap = () => {
  const { t } = useTranslation("search");

  return (
    <Card borderRadius={"xl"} className="h-full">
      <CardBody className="">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12786.789234004953!2d3.04760565!3d36.753836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fb260df364515%3A0xe3252415c67d7fa3!2sMustapha%20University%20Hospital%20Center!5e0!3m2!1sen!2sdz!4v1702486669956!5m2!1sen!2sdz"
          width="100%"
          height="100%"
          style={{ borderRadius: "12px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </CardBody>
    </Card>
  );
};

export default CardMap;
