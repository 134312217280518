import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import CardBoxStat from "./components/CardBoxStat";
import CardBarChart from "./components/CardBarChart";
import CardTraficWilaya from "./components/CardTraficWilaya";
import CardCircleChart from "./components/CardCircleChart";

const Stats = () => {
  const { t } = useTranslation("stat");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const path = window.location.pathname;

  const nextPage = () => {
    if (path.endsWith("prestateur/rdvs")) {
      navigate("./service");
    }
  };

  return (
    <div className="responsive mt-4">
      <Card borderRadius={"xl"} shadow={"sm"}>
        <CardHeader className="">
          <h1 className="font-semibold first-letter:capitalize text-2xl mb-4">
            {t("general.title")}
          </h1>
          <div className="space-x-2"></div>
        </CardHeader>
        <CardBody>
          <CardBoxStat />
          <div className="grid grid-cols-12 gap-4 mt-4">
            <div className="col-span-3">
              <CardTraficWilaya />
            </div>
            <div className="col-span-9 grid grid-cols-2 space-x-4">
              <CardCircleChart />
              <CardCircleChart />
            </div>
            <div className="col-span-3">
              <CardTraficWilaya />
            </div>
            <div className="col-span-9">
              <CardBarChart />
            </div>
          </div>
        </CardBody>
        {/* <CardFooter className="!pt-0">
          <div className="flex items-center justify-end w-full">
            <button
              onClick={nextPage}
              className="bg-secondary-100 font-medium first-letter:capitalize px-4 py-2 rounded-full text-white"
            >
              {t("general.payment")}
            </button>
          </div>
        </CardFooter> */}
      </Card>
    </div>
  );
};

export default Stats;
