import React, { useState } from "react";
import WaitingCard from "../../components/cards/WaitingCard";
import CustomButton from "../../components/custom/CustomButton";
import { useTranslation } from "react-i18next";
import PaginationBox from "../../components/ui/PaginationBox";
import useConfirmModal from "../../components/modals/ConfrimModal";
import { useDisclosure } from "@chakra-ui/react";
import ConfirmModal from "../../components/modals/ConfrimModal";
import RejectedModal from "../../components/modals/RejectedModal";

const WaitingListDetailPage = () => {
  const { t } = useTranslation("waiting");

  const [selected, setSelected] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const handleSelected = (id, type = "confirm") => {
    setSelected(id);
    if (type === "confirm") onOpen();
    else onOpenReject();
  };

  const handleClose = (type = "confirm") => {
    setSelected(null);
    if (type === "confirm") onClose();
    else onCloseReject();
  };

  return (
    <div>
      <ul className="flex items-center space-x-2 mb-4">
        <li>
          <CustomButton
            name={t("filter.all")}
            css={"!bg-secondary-200 !w-fit text-sm !px-4 !py-3"}
          />
        </li>
        <li>
          <CustomButton
            name={t("filter.specialty")}
            css={"!bg-primary-100 !w-fit text-sm !px-4 !py-3"}
          />
        </li>
        <li>
          <CustomButton
            name={t("filter.medcin")}
            css={"!bg-primary-100 !w-fit text-sm !px-4 !py-3"}
          />
        </li>
        <li>
          <CustomButton
            name={t("filter.motif")}
            css={"!bg-primary-100 !w-fit text-sm !px-4 !py-3"}
          />
        </li>
      </ul>
      <div className="grid grid-cols-1 gap-4">
        {[1, 2, 34, 5, 6, 7, 3].map((item) => (
          <WaitingCard item={item} handleSelected={handleSelected} />
        ))}
      </div>
      <div className="mt-4">
        <PaginationBox total={6} />
      </div>

      {selected !== null && (
        <ConfirmModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={() => handleClose("reject")}
        />
      )}
      {selected !== null && (
        <RejectedModal
          isOpen={isOpenReject}
          onOpen={onOpenReject}
          onClose={() => handleClose("reject")}
        />
      )}
    </div>
  );
};

export default WaitingListDetailPage;
