import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Logos, Icons } from "../constants/index";
import CircleButton from "../components/ui/CircleButton";
import SearchForm from "../components/form/SearchForm";
import { useTranslation } from "react-i18next";
import NotificationBox from "./NotificationBox";
import CustomButton from "../components/custom/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/ui/auth-slice";

const NavbarWelcome = () => {
  const { t } = useTranslation("welcome");

  const dispatch = useDispatch();

  return (
    <>
      <div className={`border-b bg-white !h-[82px]`}>
        <div className="grid-cols-12 responsive py-4 h-full !w-full grid">
          <Link to={"/"} className="col-span-3 flex items-center">
            <h1 className="text-stone-900 font-medium text-lg md:text-2xl capitalize">
              {t("general.name-web")}
            </h1>
          </Link>
          <div className="col-span-9 md:grid grid-cols-12">
            <div className="col-span-10 hidden md:flex justify-end items-center">
              <ul className="pr-20 flex w-full items-center justify-end mr-8 space-x-10">
                <li className="capitalize cursor-pointer navLinkStyle">
                  <p>{t("navbar.home")}</p>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <p>{t("navbar.about")}</p>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <p>{t("navbar.news")}</p>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <p>{t("navbar.team")}</p>
                </li>
                <li className="capitalize cursor-pointer navLinkStyle">
                  <p>{t("navbar.contact")}</p>
                </li>
              </ul>
            </div>
            <div className="col-span-2 hidden md:flex items-center justify-between space-x-10 h-full">
              <CustomButton
                name={t("navbar.signIn")}
                css="!justify-start"
                onClick={() => dispatch(authActions.replaceData("sign-in"))}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarWelcome;
