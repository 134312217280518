import React, { useRef } from "react";
import CustomSwiper from "../custom/CustomSwiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Icons } from "../../constants";
import { useTranslation } from "react-i18next";

const weeks = [
  {
    day: "05 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "06 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "07 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "08 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "09 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "10 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "11 dec",
    times: ["21:45", "22:15", "23:00"],
  },
  {
    day: "12 dec",
    times: ["21:45", "22:15", "23:00"],
  },
];

const CallendarRdv = () => {
  const { t } = useTranslation("search");

  const swiperRef = useRef(null);

  const handleSwiperNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const handleSwiperPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="grid grid-cols-10">
      {true && (
        <img
          src={Icons.LeftArrowLine}
          alt="icon left arrow"
          className="mx-auto cursor-pointer"
          onClick={handleSwiperPrev}
        />
      )}
      <div className="col-span-8">
        <CustomSwiper slides={5} ref={swiperRef}>
          {weeks.map((item, idx) => (
            <SwiperSlide key={idx}>
              <TimeDay day={item.day} times={item.times} />
            </SwiperSlide>
          ))}
        </CustomSwiper>
        <div className="flex items-center w-full justify-center space-x-2 mt-4">
          <p>{t("doctor.plus")}</p>
          <div className="bg-stone-300 rounded-2xl p-px">
            <img src={Icons.ArrowDown} alt="icon arrow down" className="w-5" />
          </div>
        </div>
      </div>
      {true && (
        <img
          src={Icons.RightArrowLine}
          alt="icon right arrow"
          className="mx-auto cursor-pointer"
          onClick={handleSwiperNext}
        />
      )}
    </div>
  );
};

export default CallendarRdv;

const TimeDay = ({ day, times }) => {
  return (
    <div>
      <p className="text-neutral-400 text-xs mb-2 text-center">{day}.</p>
      <ul className="space-y-2">
        {times.map((time, idx) => (
          <li
            key={idx}
            className={`bg-sky-500 rounded px-px py-2 text-xs font-medium text-white text-center`}
          >
            {time}
          </li>
        ))}
      </ul>
    </div>
  );
};
