import providerService from "../services/providerService";

import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { providerActions } from "../store/provider/provider-slice";
import { useDispatch, useSelector } from "react-redux";

export const useGetAllProviders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = () => {
    setIsLoading(true);
    providerService
      .getAll()
      .then((res) => {
        dispatch(providerActions.replaceData(res));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return { isLoading, fetchData };
};

export const useGetAllFavorites = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    providerService
      .getAllFavorite()
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return { isLoading, fetchData, data };
};

export const useGetAllSubAdmin = () => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    "provider-sub-admin",
    () => providerService.getAllSubAdmin()
  );

  return { isLoading, data, isError, error, refetch };
};

export const useGetAllService = () => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    "all-services",
    () => providerService.getAllServices()
  );

  return { isLoading, data, isError, error, refetch };
};

export const useEditProvider = () => {
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, watch, setValue } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    providerService
      .update(data)
      .then((res) => {
        setMessage(res.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    reset,
    message,
    setMessage,
    setValue,
    watch,
  };
};

export const useEditProviderEmail = (id) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, watch, setValue } = useForm();

  const onSubmit = (data) => {
    setMessage("");
    setError("");
    setLoading(true);
    providerService
      .updateEmail(data)
      .then((res) => {
        setMessage(res.message);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    reset,
    message,
    error,
    setMessage,
    setValue,
    watch,
  };
};

export const useCreateSubAdmin = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, watch, setValue } = useForm();

  const onSubmit = (data) => {
    setMessage("");
    setError("");
    setLoading(true);
    providerService
      .createSubAdmin(data)
      .then((res) => {
        setMessage("creation sub admin successful");
        setLoading(false);
        reset();
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    reset,
    message,
    error,
    setMessage,
    setValue,
    watch,
  };
};

export const useCreateService = (defaultValue = {}, refetch = () => {}) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, watch, setValue } = useForm();

  const onSubmit = (data) => {
    setMessage("");
    setError("");
    setLoading(true);
    providerService
      .createService(data)
      .then((res) => {
        setMessage("creation service successful");
        setLoading(false);
        reset();
        refetch();
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    reset,
    message,
    error,
    setMessage,
    setValue,
    watch,
  };
};

export const useSendNote = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, watch, setValue } = useForm();

  const onSubmit = (data) => {
    setMessage("");
    setError("");
    setLoading(true);
    providerService
      .sendNote(data)
      .then((res) => {
        setMessage("sended notes successful");
        setLoading(false);
        reset();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit,
    loading,
    onSubmit,
    reset,
    message,
    error,
    setMessage,
    setValue,
    watch,
  };
};
