import React, { useEffect, useState } from "react";
import CardInput from "./components/CardInput";
import { useTranslation } from "react-i18next";
import CardSelect from "./components/CardSelect";
import CardLogo from "./components/CardLogo";
import CardEdit from "./components/CardEdit";
import icons from "../../../constants/icons";
import ChangeEmailPopUp from "./components/ChangeEmailPopUp";
import { useDisclosure } from "@chakra-ui/react";
import { useChangeInfo, useMe } from "../../../hooks/useAuthService";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import authProviderService from "../../../services/authProviderService";
import { useEditProvider } from "../../../hooks/useProviderService";
import ChangePwdPopUp from "./components/ChangePwdPopUp";
import { useQuery } from "react-query";

const AdminPage = () => {
  const { t } = useTranslation("settings");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenPwd,
    onOpen: onOpenPwd,
    onClose: onClosePwd,
  } = useDisclosure();

  const { isLoading, data, isError, error, refetch } = useQuery(
    "personal-info",
    () => authProviderService.me()
  );

  const {
    register,
    handleSubmit,
    loading,
    onSubmit,
    message,
    setMessage,
    setValue,
    watch,
    error: errorChangeInfo,
  } = useEditProvider({
    fullName: "",
    cabinName: "",
    address: "",
    localisation: "",
    speIds: [],
    id_fascial: "",
    argument_num: "",
    type: [],
  });

  const [gps, setGps] = useState(1);
  const [pos, setPos] = useState("");
  const [openGps, setOpenGps] = useState(false);

  const fullName = watch("fullName");

  useEffect(() => {
    if (data && data.user) {
      setValue("fullName", data.user.fullName);
      setValue("cabinName", data.user.cabinName);
      setValue("email", data.user.email);
      setValue("address", data.user.address);
      setValue("localisation", data.user.localisation);
      setValue("id_fascial", data.user.id_fascial);
      setValue("argument_num", data.user.argument_num);
      setValue("logo", data.user.logo);

      if (data.user.localisation) setGps(1);
    }
  }, [data]);

  // if (isLoading)
  //   return (
  //     <div className="flex items-center justify-center min-h-[200px]">
  //       <CircularProgress isIndeterminate color="blue.400" />
  //     </div>
  //   );

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-3 gap-4"
      >
        <CardInput
          title={t("admin.denomination")}
          register={register("cabinName")}
        />
        <CardInput title={t("admin.nom")} register={register("fullName")} />
        <CardInput
          title={t("admin.agreement")}
          disabled
          register={register("argument_num")}
        />
        <CardInput title={t("admin.adresse")} register={register("address")} />
        <CardInput
          title={t("admin.identifiant")}
          disabled
          register={register("id_fascial")}
        />

        <CardEdit>
          <h3 className="font-medium first-letter:capitalize mb-3 text-sm">
            {t("admin.gps")}
          </h3>
          <div className={`border bg-gray-50 rounded-xl relative`}>
            <div
              onClick={() => {
                if (gps !== 2) setOpenGps(true);
              }}
              className="w-full bg-transparent outline-none py-3 pl-4 pr-14 text-sm cursor-pointer"
            >
              {gps === 1 && <p>{"hussein dey"}</p>}
              {gps === 1 && (
                <div className="absolute right-4 top-1/2 -translate-y-1/2">
                  <img src={icons.currentPos} alt="" />
                </div>
              )}
              {gps === 2 && (
                <input
                  type="text"
                  className="w-full bg-transparent outline-none text-sm"
                  onChange={(e) => setPos(e.target.value)}
                  value={pos}
                  {...register("localisation")}
                />
              )}
              {gps === 2 && (
                <div className="absolute right-4 top-1/2 -translate-y-1/2">
                  <img
                    src={icons.ArrowDown}
                    alt=""
                    className="w-4"
                    onClick={() => setOpenGps(true)}
                  />
                </div>
              )}
            </div>
            {openGps && (
              <ul className="absolute top-0 left-0 right-0 h-fit border border-gray-300 rounded-xl px-4 py-2 bg-gray-100">
                <li className="space-x-2 mb-2 cursor-pointer w-fit">
                  <input
                    type="radio"
                    name=""
                    id="auto"
                    checked={gps === 1}
                    onClick={() => {
                      setGps(1);
                      setOpenGps(false);
                    }}
                  />
                  <label htmlFor="auto" className="cursor-pointer">
                    {t("admin.auto")}
                  </label>
                </li>
                <li className="space-x-2 cursor-pointer w-fit">
                  <input
                    type="radio"
                    name=""
                    id="manual"
                    checked={gps === 2}
                    onClick={() => {
                      setGps(2);
                      setOpenGps(false);
                    }}
                  />
                  <label htmlFor="manual" className="cursor-pointer">
                    {t("admin.manual")}
                  </label>
                </li>
              </ul>
            )}
          </div>
        </CardEdit>

        <CardSelect title={t("admin.type")} />

        <CardEdit>
          <h3 className="font-medium first-letter:capitalize mb-3 text-sm">
            {t("admin.login")}
          </h3>
          <div className={`space-y-2`}>
            <div
              onClick={() => onOpen()}
              className="border bg-gray-50 rounded-xl w-full outline-none py-3 pl-4 pr-14 text-sm cursor-pointer relative"
            >
              <p>{t("admin.change-email")}</p>
              <img
                src={icons.RightArrowLine}
                alt=""
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
            <div
              onClick={() => onOpenPwd()}
              className="border bg-gray-50 rounded-xl w-full outline-none py-3 pl-4 pr-14 text-sm cursor-pointer relative"
            >
              <p>{t("admin.change-password")}</p>
              <img
                src={icons.RightArrowLine}
                alt=""
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>
        </CardEdit>

        <CardLogo
          title={t("admin.logo")}
          register={register}
          setValue={setValue}
          name={fullName ?? ""}
        />

        <div className="flex items-center justify-end w-full col-span-3">
          {loading ? (
            <CircularProgress isIndeterminate color="blue.400" />
          ) : (
            <button
              type="submit"
              className="bg-primary-100 font-medium first-letter:capitalize px-4 py-2 rounded-full text-white"
            >
              {t("general.save")}
            </button>
          )}
        </div>
      </form>

      <ChangeEmailPopUp isOpen={isOpen} onClose={onClose} />
      <ChangePwdPopUp isOpen={isOpenPwd} onClose={onClosePwd} />
    </div>
  );
};

export default AdminPage;
