import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const CardCircleChart = () => {
  const options = {
    chart: {
      type: "donut",
      width: 200,
    },
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {
    //         chart: {
    //           width: 50,
    //         },
    //         legend: {
    //           position: "bottom",
    //         },
    //       },
    //     },
    //   ],
  };

  return (
    <div className="bg-gray-100 rounded-xl p-2">
      <div id="chart" className="w-[300px]">
        <ReactApexChart
          options={options ?? {}}
          series={[44, 55, 41, 17, 15]}
          type="donut"
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default CardCircleChart;
