import React from "react";
import { Images, Icons, Logos } from "../constants";
import SocialMedia from "../components/ui/SocialMedia";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("global");
  return (
    <footer className="bg-white">
      <div className="grid grid-cols-2 md:flex items-center md:items-start md:justify-between py-5 md:py-20 responsive">
        <div className="mt-8 md:mt-0 md:hidden col-span-2 mb-10">
          <h3 className="first-letter:capitalize font-medium text-xl mb-6 text-center md:text-left">
            {t("footer.stayUpdated")}
          </h3>
          <ul className="flex justify-center">
            <div className="flex items-center h-10">
              <input
                type="text"
                placeholder={t("footer.enterEmail")}
                className="px-4 outline-none bg-gray-100 h-full"
              />
              <div className="bg-blue-600 h-full flex items-center justify-center px-2">
                <img
                  src={Icons.SendWhite}
                  alt="icon send white"
                  className="w-6"
                />
              </div>
            </div>
          </ul>
        </div>
        <div className="md:w-[200px] col-span-2">
          <h1 className="capitalize font-bold text-2xl md:text-3xl">
            {t("footer.assistini")}
          </h1>
          <p className="text-sm mt-4">{t("footer.description")}</p>
          <p className="text-sm mb-2 mt-4 capitalize text-left">
            {t("footer.algiersAlgeria")}
          </p>
          <p className="text-sm mb-2 capitalize text-left">+213 456 7899</p>
          <p className="text-sm mb-2 capitalize md:text-left">
            www.assistini.com
          </p>
        </div>
        <div className="mt-8 md:mt-0">
          <h3 className="capitalize font-medium text-xl mb-4 md:mb-6">
            {t("footer.menu")}
          </h3>
          <ul className="space-y-2 md:space-y-4">
            <li className="textStyle">{t("footer.products")}</li>
            <li className="textStyle">{t("footer.rooms")}</li>
            <li className="textStyle">{t("footer.inspirations")}</li>
            <li className="textStyle">{t("footer.aboutUs")}</li>
            <li className="textStyle">{t("footer.termsPolicy")}</li>
          </ul>
        </div>
        <div className="mt-8 md:mt-0">
          <h3 className="capitalize font-medium text-xl mb-4 md:mb-6">
            {t("footer.account")}
          </h3>
          <ul className="space-y-2 md:space-y-4">
            <li className="textStyle">{t("footer.myAccount")}</li>
            <li className="textStyle">{t("footer.checkout")}</li>
            <li className="textStyle">{t("footer.myCart")}</li>
            <li className="textStyle">{t("footer.myCatalog")}</li>
            <li className="textStyle text-white">s</li>
          </ul>
        </div>
        <div className="mt-8 md:mt-0 col-span-2">
          <h3 className="capitalize font-medium text-xl mb-4 md:mb-6">
            {t("footer.stayConnected")}
          </h3>
          <ul className="space-y-2 md:space-y-4">
            <li className="textStyle">{t("footer.facebook")}</li>
            <li className="textStyle">{t("footer.instagram")}</li>
            <li className="textStyle">{t("footer.twitter")}</li>
          </ul>
        </div>
        <div className="mt-8 md:mt-0 hidden md:block">
          <h3 className="first-letter:capitalize font-medium text-xl mb-6 text-center md:text-left">
            {t("footer.stayUpdated")}
          </h3>
          <ul className="space-y-4">
            <div className="flex items-center h-10">
              <input
                type="text"
                placeholder={t("footer.enterEmail")}
                className="px-4 outline-none bg-gray-100 h-full"
              />
              <div className="bg-blue-600 h-full flex items-center justify-center px-2">
                <img src={Icons.SendWhite} alt="icon send white" />
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div className="responsive hidden md:block">
        <div className="mx-auto md:w-2/3 grid grid-cols-3 gap-10">
          <div className="flex items-center space-x-3 flex-col md:flex-row">
            <img
              src={Icons.Trophy}
              alt="icon trophy"
              className="w-6 md:w-fit"
            />
            <div>
              <h5 className="text-neutral-700 text-sm md:text-lg text-center md:text-left">
                {t("footer.highQuality")}
              </h5>
              <p className="text-zinc-500 text-xs md:text-base text-center md:text-left">
                {t("footer.crafted")}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 flex-col md:flex-row">
            <img
              src={Icons.Guarante}
              alt="icon guarante"
              className="w-6 md:w-fit"
            />
            <div>
              <h5 className="text-neutral-700 text-sm md:text-lg text-center md:text-left">
                {t("footer.warranyProtection")}
              </h5>
              <p className="text-zinc-500 text-xs md:text-base text-center md:text-left">
                {t("footer.over")}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 flex-col md:flex-row">
            <img
              src={Icons.Support}
              alt="icon support"
              className="w-6 md:w-fit"
            />
            <div>
              <h5 className="text-neutral-700 text-sm md:text-lg text-center md:text-left">
                {t("footer.support")}
              </h5>
              <p className="text-zinc-500 text-xs md:text-base text-center md:text-left">
                {t("footer.dedicatedSupport")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:mt-20 bg-neutral-100 pb-4 md:pb-16 pt-4 md:pt-20">
        <div className="responsive flex items-end justify-between">
          <div className="text-xs md:text-base">
            <p className=" text-stone-600 text-sm">{t("footer.poweredBy")}</p>
            <p className=" text-stone-600 text-sm">{t("footer.allRight")}</p>
          </div>
          <div className="flex items-center space-x-2">
            <img src={Icons.Twitter} alt="icon twitter" />
            <img src={Icons.Instagram} alt="icon instagram" />
            <img src={Icons.Facebook} alt="icon facebook" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
