import React from "react";
import { useTranslation } from "react-i18next";
import { Images } from "../../constants";

const BannerSection = () => {
  const { t } = useTranslation("welcome");

  return (
    <div className="w-full relative ">
      <img
        src={Images.BannerWelcome}
        alt="image banner welcome"
        className="w-full h-[500px] md:h-fit"
      />
      <div className="absolute right-3 md:right-0 top-0 bottom-16 md:bottom-0 left-0 text-stone-700 text-xs md:text-sm">
        <div className="responsive flex items-end md:items-center justify-end h-full">
          <div className="mx-auto md:mx-0 text-sm ">
            <p className="w-fit">{t("general.name")}</p>
            <p className="w-fit">{t("banner_section.sub_title")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
