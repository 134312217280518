import CalendarCheck from "../assets/icons/Calendar_check.svg";
import Chat from "../assets/icons/Chat.svg";
import EditRectangle from "../assets/icons/Edit_rectangle.svg";
import Filter from "../assets/icons/filter.svg";
import Folder from "../assets/icons/folder.svg";
import Home from "../assets/icons/Home.svg";
import Notification from "../assets/icons/Notification.svg";
import Profile from "../assets/icons/Profile.svg";
import Search from "../assets/icons/Search.svg";
import Star from "../assets/icons/Star.svg";
import Work from "../assets/icons/Work.svg";
import UserRectangle from "../assets/icons/User_rectangle_1.svg";
import ArrowDown from "../assets/icons/Arrow - Down 2.svg";
import Category from "../assets/icons/Category.svg";
import Location from "../assets/icons/location.svg";
import Phone from "../assets/icons/phone.svg";
import RightArrow from "../assets/icons/right arrow.svg";
import MediaFile from "../assets/icons/media file.svg";
import FileDuplicateBlue from "../assets/icons/file_duplicate.svg";
import TelescopeBlue from "../assets/icons/telescope blue.svg";
import locationBlue from "../assets/icons/location blue.svg";
import InfoBlue from "../assets/icons/info blue.svg";
import Info from "../assets/icons/info.svg";
import TimeTableBlue from "../assets/icons/timetable blue.svg";
import SendBlue from "../assets/icons/send blue.svg";
import PhoneWhite from "../assets/icons/Phone white.svg";
import RightArrowLineWhite from "../assets/icons/right arrow with line white.svg";
import ChatGreen from "../assets/icons/chat green.svg";
import RightArrowLine from "../assets/icons/right arrow line.svg";
import LeftArrowLine from "../assets/icons/left arrow line.svg";
import SendWhite from "../assets/icons/send white carbon.svg";
import Trophy from "../assets/icons/trophy 1.svg";
import Guarante from "../assets/icons/guarantee.svg";
import Support from "../assets/icons/customer-support.svg";
import Facebook from "../assets/icons/facebook.svg";
import Instagram from "../assets/icons/instagram.svg";
import Twitter from "../assets/icons/twitter.svg";
import RightArrowLine2 from "../assets/icons/arrow right line2.svg";
import Google from "../assets/icons/google.svg";
import Mail from "../assets/icons/Message_light.svg";
import Lock from "../assets/icons/lock.svg";
import PlusCircle from "../assets/icons/Plus_circle.svg";
import PlusWhite from "../assets/icons/plus white.svg";
import LeftArrowLineBlue from "../assets/icons/left arrow blue.svg";
import User from "../assets/icons/user.svg";
import Calendar from "../assets/icons/Calendar_active.svg";
import Sexe from "../assets/icons/sexe.svg";
import PhoneBlack from "../assets/icons/Phone-black.svg";
import Card from "../assets/icons/card.svg";
import FileText from "../assets/icons/file-text.svg";
import PositionWhite from "../assets/icons/position-white.svg";
import trash from "../assets/icons/trash.svg";
import editGray from "../assets/icons/edit-gray.svg";
import editBleu from "../assets/icons/edit-blue.svg";
import camera from "../assets/icons/camera.svg";
import users from "../assets/icons/users.svg";
import trashBleu from "../assets/icons/trash-blue.svg";
import pages from "../assets/icons/pages.svg";
import editWhite from "../assets/icons/editWhite.svg";
import documentUpload from "../assets/icons/document-upload.svg";
import document from "../assets/icons/Document.svg";
import currentPos from "../assets/icons/current-pos.svg";
import boxChevronDown from "../assets/icons/box-chevron-down.svg";
import checkBlue from "../assets/icons/check-blue.svg";
import GamMenu from "../assets/icons/gam-menu.svg";
import BarPrice from "../assets/icons/bars-price.svg";
import ThreeDots from "../assets/icons/threedots.svg";

export default {
  ThreeDots,
  BarPrice,
  GamMenu,
  checkBlue,
  boxChevronDown,
  currentPos,
  document,
  documentUpload,
  editWhite,
  trash,
  trashBleu,
  pages,
  users,
  editBleu,
  editGray,
  camera,
  PositionWhite,
  User,
  Calendar,
  Sexe,
  PhoneBlack,
  Card,
  FileText,
  LeftArrowLineBlue,
  Google,
  PlusCircle,
  PlusWhite,
  Mail,
  Lock,
  RightArrowLine2,
  Facebook,
  Instagram,
  Twitter,
  Trophy,
  Guarante,
  Support,
  CalendarCheck,
  Chat,
  EditRectangle,
  Filter,
  Folder,
  Home,
  Notification,
  Profile,
  Search,
  Star,
  Work,
  UserRectangle,
  ArrowDown,
  Category,
  Location,
  Phone,
  RightArrow,
  MediaFile,
  FileDuplicateBlue,
  TelescopeBlue,
  locationBlue,
  InfoBlue,
  Info,
  TimeTableBlue,
  SendBlue,
  PhoneWhite,
  RightArrowLineWhite,
  ChatGreen,
  LeftArrowLine,
  RightArrowLine,
  SendWhite,
};
