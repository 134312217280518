import axios from "axios";
import Converter from "../helpers/Converter";

const url = process.env.REACT_APP_URL_API;

const get = (id) => {
  return axios
    .get(`${url}/providers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => response.data.data)
    .catch((error) => error);
};

const getAll = () => {
  return axios.get(`${url}/providers`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const getAllFavorite = () => {
  return axios.get(`${url}/users/fav`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const getAllByFilter = (payload) => {
  const filters = Converter.filterParams(payload);
  return axios.get(`${url}/providers?${filters}`);
};

const getAllSubAdmin = () => {
  return axios.get(`${url}/subadmin`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const getAllSpecialites = () => {
  return axios.get(`${url}/providers/specialties`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const getAllServices = () => {
  return axios.get(`${url}/services`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const deleteService = (id) => {
  return axios.delete(`${url}/services/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const createService = (payload) => {
  return axios.post(
    `${url}/services`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

const createSubAdmin = (payload) => {
  return axios.post(
    `${url}/subadmin`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

const sendNote = (payload) => {
  return axios.post(
    `${url}/note`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
};

const update = (payload) => {
  return axios.put(`${url}/providers/provider`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

const updateEmail = (payload) => {
  return axios.post(`${url}/providers/changeemail`, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });
};

export default {
  get,
  getAll,
  getAllFavorite,
  getAllSpecialites,
  getAllByFilter,
  getAllSubAdmin,
  createSubAdmin,
  sendNote,
  update,
  updateEmail,
  getAllServices,
  deleteService,
  createService,
};
