import React, { useEffect, useState } from "react";
import CardEdit from "./CardEdit";
import icons from "../../../../constants/icons";
import { useTranslation } from "react-i18next";

const CardLogo = ({ title, image, name = "", setValue }) => {
  const { t } = useTranslation("settings");

  const [imageSrc, setImageSrc] = useState(null);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = () => {
    setImageSrc(null);
  };

  // useEffect(() => {
  //   setValue("logo", imageSrc);
  // }, [imageSrc]);

  return (
    <CardEdit>
      <h3 className="font-medium first-letter:capitalize mb-3 text-sm">
        {title}
      </h3>
      <div className="flex items-center space-x-4 w-full">
        <div className="min-w-[80px] min-h-[80px] rounded-full border border-primary-100 bg-primary-100 bg-opacity-10 flex items-center justify-center overflow-hidden">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded"
              className="w-[80px] h-[80px] object-cover"
            />
          ) : (
            <p className="uppercase font-medium">{name.substring(0, 3)}</p>
          )}
        </div>

        <div className="w-full space-y-3">
          <button
            className="w-full border border-gray-200 rounded-xl px-4 py-2 flex items-center justify-center space-x-2 font-medium cursor-pointer"
            type="button"
          >
            <input
              type="file"
              id="file-upload"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <label
              htmlFor="file-upload"
              className="flex items-center space-x-2 cursor-pointer"
            >
              <img src={icons.camera} alt="" className="w-4" />
              <p className="first-letter:capitalize">{t("admin.upload")}</p>
            </label>
          </button>
          {imageSrc && (
            <button
              className="w-full border border-gray-200 rounded-xl px-4 py-2 flex items-center justify-center space-x-2 text-red-600 font-medium "
              onClick={handleDelete}
            >
              <img src={icons.trash} alt="" className="w-4" />
              <p className="first-letter:capitalize">{t("admin.delete")}</p>
            </button>
          )}
        </div>
      </div>
    </CardEdit>
  );
};

export default CardLogo;
