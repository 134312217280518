import React, { useState } from "react";
import { Icons } from "../../constants";

const SelectPatientBox = () => {
  const [patients, setPatients] = useState([
    "ben mohamed",
    "sdfsksadjf",
    "sdfsksa",
  ]);

  return (
    <div className="bg-gray-100 rounded-md p-4 flex flex-wrap">
      {patients.map((patient) => (
        <div
          className=" bg-blue-500 rounded-md justify-center items-center text-white uppercase p-2 w-fit inline-block mb-2 mr-2 text-sm md:text-base"
          key={patient}
        >
          {patient}
        </div>
      ))}
      <img src={Icons.PlusCircle} alt="" />
    </div>
  );
};

export default SelectPatientBox;
