import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useDisclosure,
  CircularProgress,
} from "@chakra-ui/react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Images } from "../../../constants";
import { useGetDetailInvoice } from "../../../hooks/useInvoiceService";

const Detail = () => {
  const { t } = useTranslation("invoice");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { id } = useParams();

  const path = window.location.pathname;

  const { isLoading, data, isError, error, refetch } = useGetDetailInvoice(id);

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center min-h-[300px]">
          <CircularProgress isIndeterminate color="blue.400" />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-6">
          <div className="rounded-xl bg-[#F2F5F9] p-4">
            <div className="bg-white p-4 rounded-xl">
              <div className="bg-[#F2F5F9] rounded-xl p-4">
                <div className="flex items-center justify-between">
                  <img
                    src={Images.Hospital}
                    alt=""
                    className="w-[100px] object-cover"
                  />
                  <div>
                    <p className="text-right text-sm text-[#60737D]">
                      {t("general.invoice-no")}
                    </p>
                    <p className="text-right font-semibold text-[#121722] text-3xl">
                      #000345
                    </p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="mt-4">
                    <p className="text-3xl text-[#121722] font-semibold">
                      {t("general.invoice")}
                    </p>
                    <p className="text-sm text-[#60737D]">
                      {t("general.billed-to")}
                    </p>
                    <p className="font-semibold text-[#121722] mt-4">
                      {data?.data?.data?.patient}
                    </p>
                    <p className="text-sm text-[#60737D]">
                      addresse / contact info
                    </p>
                  </div>
                  <div className="">
                    <p className="text-right text-sm text-[#60737D]">
                      {t("general.issued-on")}
                    </p>
                    <p className="text-sm text-right">december 12, 2024</p>
                    <p className="text-right text-sm text-[#60737D] mt-4">
                      {t("general.payment-due")}
                    </p>
                    <p className="text-sm text-right">december 12, 2024</p>
                  </div>
                </div>
              </div>
              <div className="p-4 space-y-3">
                <div className="grid grid-cols-6">
                  <p className="col-span-3 text-lg font-semibold">
                    {t("general.services")}
                  </p>
                  <p className="text-right text-[#60737D]">
                    {t("general.qnt")}
                  </p>
                  <p className="text-right text-[#60737D]">
                    {t("general.price")}
                  </p>
                  <p className="text-right text-[#60737D]">
                    {t("general.total")}
                  </p>
                </div>
                {Array.from({ length: 3 }).map((item) => (
                  <div className="grid grid-cols-6">
                    <p className="col-span-3">item 1</p>
                    <p className="text-right text-[#60737D]">1</p>
                    <p className="text-right text-[#60737D]">4,000.00</p>
                    <p className="text-right text-[#60737D]">4,000.00</p>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-end mt-4">
                <div className="flex items-center justify-between bg-[#FBF7ED] p-3 rounded-lg w-[350px]">
                  <p className="text-[#60737D] text-sm first-letter:capitalize space-x-3">
                    <span>{t("invoice.total")}</span>
                    <span>({t("invoice.dzd")})</span>
                  </p>
                  <p className="text-right font-semibold text-2xl">
                    {data?.data?.data?.total_price}.00
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-xl bg-[#F2F5F9] p-4 h-full">
            <div className="bg-white p-4 rounded-xl">
              <div className="bg-[#F2F5F9] rounded-xl p-4 h-full">
                <div className="flex items-center justify-between">
                  <img
                    src={Images.Hospital}
                    alt=""
                    className="w-[100px] object-cover"
                  />
                  <div>
                    <p className="text-right text-sm text-[#60737D]">
                      {t("general.invoice-no")}
                    </p>
                    <p className="text-right font-semibold text-[#121722] text-3xl">
                      #000345
                    </p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="mt-4">
                    <p className="text-3xl text-[#121722] font-semibold">
                      {t("general.compte-rendu")}
                    </p>
                    <p className="text-lg font-medium">scanner</p>
                    <p className="font-medium">{t("general.indication")}</p>
                  </div>
                  <div className="">
                    <p className="text-right text-sm text-[#60737D]">
                      {t("general.issued-on")}
                    </p>
                    <p className="text-sm text-right">december 12, 2024</p>
                    <p className="text-right text-sm text-[#60737D] mt-4">
                      {t("general.payment-due")}
                    </p>
                    <p className="text-sm text-right">december 12, 2024</p>
                  </div>
                </div>
              </div>
              <div className="p-4 space-y-3">
                <h3 className="text-lg font-semibold first-letter:capitalize">
                  {t("general.result")}
                </h3>
                <p className="text-sm text-gray-500">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
                  et doloremque minima perspiciatis ea eos alias ipsum expedita
                  omnis harum. Doloribus, ipsa qui deserunt debitis deleniti
                  sequi distinctio consequuntur excepturi veritatis tenetur
                  nesciunt eum, praesentium sit nobis, nihil impedit porro!
                </p>
                <h3 className="text-lg font-semibold first-letter:capitalize">
                  {t("general.conclusion")}
                </h3>
                <p className="text-sm text-gray-500">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. In,
                  ipsam!
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-full flex justify-center mt-6 mb-4">
            <div className="w-[400px] space-y-3">
              <h1 className="text-center font-bold text-3xl first-letter:capitalize">
                {t("general.control-your-doc")}
              </h1>
              <button className="text-white bg-blue-600 font-semibold uppercase p-5 rounded-xl w-full">
                {t("general.download")}
              </button>
              <button className="text-white bg-blue-600 font-semibold uppercase p-5 rounded-xl w-full">
                {t("general.share")}
              </button>
              <button
                className="text-white bg-green-600 font-semibold uppercase p-5 rounded-xl w-full"
                onClick={() => navigate("/prestateur/invoices")}
              >
                {t("general.finish")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Detail;
