import React from "react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomButton from "../custom/CustomButton";
import useModal from "../modals/ConfrimModal";

const WaitingCard = ({ item, handleSelected }) => {
  const { t } = useTranslation("waiting");

  return (
    <Card borderRadius={"xl"} shadow={"sm"}>
      <CardHeader>
        <div className="flex items-center space-x-4">
          <div className="bg-red-50 rounded-full p-5 w-fit">
            <p className="bg-red-200 rounded-full text-white w-6 h-6 flex items-center justify-center">
              A
            </p>
          </div>
          <div>
            <h3 className="text-xl capitalize">benrabah mohamed</h3>
            <p className="text-gray-50 font-light">13 octobre 2024</p>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <h3 className="text-xl font-bold first-letter:capitalize">
          {t("card.motif")}
        </h3>
        <p className="text-xl">Consultation de médecine general </p>
        <ul className="mt-4 font-light">
          <li className="flex items-center space-x-2">
            <p>{t("card.type")}:</p>
            <p>Au cabinet</p>
          </li>
          <li className="flex items-center space-x-2">
            <p>{t("card.document")}:</p>
            <p>2 {t("card.file")}</p>
          </li>
          <li className="flex items-center space-x-2">
            <p>{t("card.date")}:</p>
            <p>Jeu, 08 Jan, 2024</p>
          </li>
          <li className="flex items-center space-x-2">
            <p>{t("card.hour")}:</p>
            <p>9:30 a.m</p>
          </li>
        </ul>
      </CardBody>
      <CardFooter className="space-x-4">
        <CustomButton
          name={t("card.accept")}
          css="!bg-secondary-200 !w-[150px] text-sm !py-2 font-medium !px-4"
          onClick={() => handleSelected(item.id ?? 2, "confirm")}
        />
        <CustomButton
          name={t("card.refuse")}
          css="!bg-gray-100 !w-[150px] text-sm !px-4 !py-2 font-medium !text-gray-200"
          onClick={() => handleSelected(item.id ?? 2, "reject")}
        />
      </CardFooter>
    </Card>
  );
};

export default WaitingCard;
